<template>
  <div>
    <ProductsListing></ProductsListing>
  </div>
</template>

<script>
  import ProductsListing from '../../components/ProductsListing.vue';

  export default {
    name: 'Products',
    components: {
      ProductsListing
    }
  };
</script>
