class IncludeDeletedEnumWeb {
  constructor() {
    this.No = 0;
    this.Yes = 1;
  }
  
  GetMatchingKey(value) {
    return Object.keys(this).find(enumValue => this[enumValue] === value);
  }
  
}
export let includeDeletedEnumWeb = new IncludeDeletedEnumWeb();